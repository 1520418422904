import {API_URL} from '../Constants.js'

const fetchEvents = (data) => {

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  },
        body: JSON.stringify(data)
    };

    return fetch(API_URL + '/f-events-vehicle-date', requestOptions)
        .then((response) => response.json())
        .then((data) => {
                console.log(data);
            // return Promise.resolve(data);



                const newFeaturesList = [];
                for (let i = 0; i < data.length; i++) {
                    const id = i;




                        if (typeof data[i].latitude !== '0') {

                            /*so can be used by mapbox get*/
                            data[i].eventType = data[i].analysis.eventType;
                            data[i].severity = data[i].analysis.severity;

                            newFeaturesList.push({
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [data[i].lng, data[i].lat],
                                },
                                properties:
                                    data[i]
                                ,
                            });
                        }


                }

                return Promise.resolve({
                    type: 'FeatureCollection',
                    features: newFeaturesList,
                });








            }
        );
}


export default fetchEvents;
