import {API_URL} from '../Constants.js'


const fetchVehicles = () => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  },
    };


    return fetch(API_URL + '/f-vehicles', requestOptions)
        .then((response) => response.json())
        .then((data) => {
                console.log(data);

                const newFeaturesList = [];
                for (let i = 0; i < data.length; i++) {
                    const id = i;



                    //const { longitude, latitude } = {data[i].lastLocation.lng, data[i].lastLocation.lat};
                    if (typeof data[i].lastLocation !== 'undefined') {

                        if (typeof data[i].lastLocation.lng !== 'undefined') {

                            newFeaturesList.push({
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [data[i].lastLocation.lng, data[i].lastLocation.lat],
                                },
                                properties: {
                                    id,
                                    dn: data[i].dn,
                                    registration: data[i].registration,
                                    lastLocation: data[i].lastLocation,
                                    deviceStatus: data[i].deviceStatus,
                                    drivingStatus: data[i].drivingStatus,
                                    driver: data[i].driver,
                                    tags: data[i].tags
                                },
                            });
                        }
                    }
                }

                return Promise.resolve({
                    type: 'FeatureCollection',
                    features: newFeaturesList,
                });


            }
        );
}


export default fetchVehicles;
