/* src/components/EndMarker.js */
import React from 'react';
import ImageMfl from "./ImageMfl";
import {FaFlag} from "react-icons/fa";




const arrowDown ={
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #000',
    position: 'absolute',
    top: '60px',
    left: '60px'
}


const arrowUp ={
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #000',
    position: 'absolute',
    top: '-10px',
    left: '60px'
}

const arrowLeft ={
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid #000',
    position: 'absolute',
    top: '20px',
    left: '-10px'
}

const arrowRight ={
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid #000',
    position: 'absolute',
    top: '20px',
    left: '140px'
}

const markerHolder ={
    padding: '10px',
    position: 'relative',
}


const StartMarker = ({ journey }) => {

   if(journey === null) {
       return null
   }



    if(journey.details.startAddress === null) {
        journey.details.startAddress = {
            place: '',
            town: ''
        }
    }




    let headings = [];

    journey.features.forEach((f) => {
        headings.push(f.properties.head);
    });



    // console.log(headings.slice(0,10));


    const averageLastTenHeadings = (headings.slice(0,10).reduce((acc, val) => acc + val)) / 10;

    // console.log(averageLastTenHeadings);

    let finishOffsetX = ((Math.sin(   averageLastTenHeadings * Math.PI/180   )) );
    // console.log('x: ' + finishOffsetX);
    let finishOffsetY= ((Math.cos(   averageLastTenHeadings * Math.PI/180   )) );
    // console.log('y: ' + finishOffsetY);


    let arrowStyle = arrowDown;


    if(Math.abs(finishOffsetX) > Math.abs(finishOffsetY)) {
        // console.log('updown')

        finishOffsetX = 0;

        if(finishOffsetY > 0) {
            finishOffsetY = 70
            arrowStyle = arrowUp;
        }  else {
            finishOffsetY = -70
            arrowStyle = arrowDown;
        }


    } else {
        // console.log('leftRight')

        finishOffsetY = 0;

        if(finishOffsetX > 0) {
            finishOffsetX = -150
            arrowStyle = arrowRight;
        }  else {
            finishOffsetX = 150
            arrowStyle = arrowLeft;
        }

    }









    const markerStyle = {
        marginLeft: finishOffsetX,
        marginTop: finishOffsetY,
        background: 'rgba(255,255,255,0.75)',
        border: 'solid 1px #cccccc'
    }

    // console.log(`arrowStyle = ${arrowStyle}`);
    // console.log(arrowStyle);


    return (
        <div className="startMarker" style={markerStyle}>

            <div style={markerHolder}>

                <div>

                    <FaFlag style={{color: 'green'}}/>

                    {` ${(new Date(journey.details.startTime)).toLocaleString().slice(11, 17)} ${journey.details.startAddress.place},
                 ${journey.details.startAddress.town} 
                 `}


                </div>


                <div style={arrowStyle}/>


            </div>

            {/*{journey.details.duration}&nbsp;*/}


        </div>
    )


};

export default StartMarker;
