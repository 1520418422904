import {API_URL} from '../Constants.js'

const fetchJourneys = (dn, dateString) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  },
    };


    return fetch(API_URL + `/f-journeys/${dn}/${dateString}`,  requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
        .then((data) => {
                // console.log(data);

                return Promise.resolve(data);


            }).catch(err=>{
            console.log(err)});
}


export default fetchJourneys;
