

import React,  { useState } from 'react';
import {ReactComponent as MflLogo} from "../svgs/logo-myfleetlive.svg";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {API_URL} from '../Constants.js'

// import useToken from './useToken.js';

async function loginUser(credentials) {
    return fetch(API_URL + '/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}


const Login = ({ setToken }) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    // const { token, setToken } = useToken();

    const handleSubmit = async e => {
        e.preventDefault();
        const user = await loginUser({
            email,
            password
        });


        console.log(user);
        console.log(user.access_token);
        console.log(user.result);

        if(typeof user.access_token !== 'undefined') {
            setToken(user);
        } else {
            console.log('failed')
        }



    }


    return(





        <div style={{background: '#000000'    , height: '100vh', paddingTop: '100px',
            backgroundImage: 'url("/img/background1.jpg")' ,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom'

        }}>





            <MflLogo className="logo" width="600px" style={
                {fill:'#fff7f7', marginLeft: 'auto',  marginRight: 'auto', display: 'block'}
            }/>

            <br/><br/><br/><br/>

        <form onSubmit={handleSubmit}     style={
            {background:'#e2e2e2', marginLeft: 'auto',  marginRight: 'auto', display: 'block',
                width: '300px', padding: '30px', textAlign: 'center' }}>





                    <InputText style={{backgroundColor: '#ffffff'}} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />



            <br/><br/>



                    <Password  onChange={(e) => setPassword(e.target.value)} placeholder="Password" />



            <br/><br/>



            <div>
                <Button   style={{background: '#F0750A'}}    type="submit">Submit</Button>
            </div>
        </form>
        </div>
    )
}

export default Login;





// https://jooinn.com/img/get
