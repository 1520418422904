import React, {useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import CustomIcons from './CustomIcons';

import MapCustomControl from './MapCustomControl.js';

import EndMarker from './EndMarker.js'
import StartMarker from './StartMarker.js'

import ReactDOM from "react-dom";
import Popup from "./Popup";

const styles = {
    flex: "1",
    position: "relative"
};


const CustomControl = new MapCustomControl();

const el = document.createElement('div');
const marker = new mapboxgl.Marker(el);

const elStart = document.createElement('div');
const markerStart = new mapboxgl.Marker(elStart);



const Map2 = ({vehicles, journey, event, events, zoom, handleVehicle, handleJourney, handleEvent,
                  handleEndPic, endPic, sideBarWidthPx}) => {
    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);

    const popUpRef = useRef(new mapboxgl.Popup({offset: 15}));








    useEffect(() => {
        mapboxgl.accessToken =

            "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";

        const mapStyle = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';



        // const markerRef = useRef(new mapboxgl.Marker(el));



        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center: [0.9142, 51.9037],
                zoom: 12.5
            });





            map.addControl(
                new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    countries: 'gb',
                    mapboxgl
                }),  'bottom-right'
            );


            map.addControl(CustomControl, 'bottom-right');



            map.on('load', () => {

                console.log('map loaded');


                CustomIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    customIcon.src = icon.src;
                });




                setMap(map);
                map.resize();

                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();


                // map.getSource('vehicle-points-data').setData(props.data.data);

                // add the data source for new a feature collection with no features
                map.addSource('vehicle-points-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                    cluster: true,
                    clusterMaxZoom: 16, // Max zoom to cluster points on
                    clusterRadius: 40
                });


                map.addSource('events-data', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [],
                    },
                    // cluster: true,
                    // clusterMaxZoom: 16, // Max zoom to cluster points on
                    // clusterRadius: 40
                });

                map.addSource('journeyLine', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                });


                map.addSource('journey-points', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: []
                        }
                    }
                });


                map.addSource('pink-circle-source', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                });


               /* map.addSource('start-flag-source', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                });*/


               /* map.addSource('finish-flag-source', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: []
                    }
                });*/


                // now add the layer, and reference the data source above by name
                map.addLayer({
                    id: 'vehicle-points-layer',
                    source: 'vehicle-points-data',
                    type: 'symbol',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        // 'icon-image': 'rectangle-orange-5',
                        'icon-image': 'whitePlate',  // ['get', 'deviceStatus'],
                        'icon-ignore-placement': false,

                        // 'icon-text-fit': 'both',
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,

                        'text-field': ['get', 'registration'],
                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],


                        'text-size': 13,
                        'icon-size': 1,  // 0.75,
                        'icon-anchor': 'left',
                        // 'icon-offset': [-15, 0],
                        'icon-offset': [0, 0],
                        'text-anchor': 'bottom-left',
                        'text-offset': [1, 0.6],

                    },
                    paint: {
                        // Sleep Offline Driving
                        'icon-color': '#727206',
                        'text-color': [
                            'case',
                            ['==', 'Driving', ['get', 'deviceStatus']], '#000000',
                            ['==', 'Sleep', ['get', 'deviceStatus']], '#5f5f5f',
                            ['==', 'Offline', ['get', 'deviceStatus']], '#5f5f5f',
                            '#000000'
                        ]
                    }
                });


                map.addLayer({
                    id: 'journey-points-layer',
                    source: 'journey-points',
                    type: 'symbol',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        // full list of icons here: https://labs.mapbox.com/maki-icons
                        //'icon-image': 'airport-15',
                        'icon-image': 'lpArrow',
                        'icon-ignore-placement': false,
                        'icon-padding': 0,
                        'icon-allow-overlap': false,
                        // 'icon-size': 0.6,

                        'icon-size': {
                            stops: [
                                [1, 0.6],
                                [14, 0.6],
                                [15, 0.9]
                            ]
                        },


                        'icon-rotate': ['get', 'head'],
                    },
                    paint: {
                       // 'icon-color': '#ffffff'

                        /*'icon-color': {
                            stops: [
                                [1, '#ffffff'],
                                [14, '#ffffff'],
                                [15, '#7318ec']
                            ]
                        },*/

                        'icon-color': [
                            'case',
                            ['==', true, ['get', 'storeForward']], '#ec1818',
                            ['==', false, ['get', 'storeForward']], '#ffffff',
                            ['==', true, ['get', 'alarm']], '#5165fa',
                            '#ffffff'
                        ],



                    }
                });


                map.addLayer({
                    id: 'events-layer',
                    source: 'events-data',
                    type: 'symbol',
                    // filter: ['!', ['has', 'point_count']],
                    layout: {
                        // 'icon-image': 'pin-acceleration',
                        // 'icon-image': 'circle-svg',  // ['get', 'deviceStatus'],

                        'icon-image': [
                            'case',
                            ['==', 'severe', ['get', 'severity']], 'circle-red-svg',
                            ['==', 'moderate', ['get', 'severity']], 'circle-svg',
                            'circle-svg'
                        ],



                        'icon-ignore-placement': false,

                        // 'icon-text-fit': 'both',
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,

                        //'text-field': '!',
                        //'text-field': 'EVENT!',
                        //'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],


                        //'text-size': 20,
                        'icon-size': 1,  // 0.75,
                        'icon-anchor': 'center',
                        // 'icon-offset': [-15, 0],
                       // 'icon-offset': [0, 0],
                       // 'text-anchor': 'bottom-left',
                       // 'text-offset': [1, 1],

                    },
                    paint: {
                        // Sleep Offline Driving


                        'icon-color': [
                            'case',
                            ['==', 'bump', ['get', 'eventType']], '#3354b8',
                            ['==', 'cornering', ['get', 'eventType']], '#4a8939',
                            ['==', 'braking', ['get', 'eventType']], '#e04242',
                            ['==', 'acceleration', ['get', 'eventType']], '#fa8b3a',
                            '#000000'
                        ],

                        'text-color': '#ffffff'


                       /* 'text-color': [
                            'case',
                            ['==', 'bump', ['get', 'eventType']], '#3354b8',
                            ['==', 'cornering', ['get', 'eventType']], '#4a8939',
                            ['==', 'braking', ['get', 'eventType']], '#e04242',
                            ['==', 'acceleration', ['get', 'eventType']], '#fa8b3a',
                            '#000000'
                        ]*/
                    }
                });





                map.addLayer({
                    id: 'pink-event-circle',
                    type: 'circle',
                    source: 'pink-circle-source',
                    paint: {
                        'circle-radius': 70,
                        'circle-color': 'rgba(115,24,236,0.13)',
                    },
                    filter: ['==', '$type', 'Point'],
                });   // , 'eventsLayer'


               /* map.addLayer({
                    id: 'start-flag-layer',
                    type: 'symbol',
                    source: 'start-flag-source',
                    layout: {
                        'icon-image': 'startFlag',
                        'icon-size': 1,
                        'icon-anchor': 'bottom-left',
                        'icon-offset': [-7, 0],
                    }
                });*/


                /*map.addLayer({
                    id: 'finish-flag-layer',
                    type: 'symbol',
                    source: 'finish-flag-source',
                    layout: {
                        'icon-image': 'finishFlag',
                        'icon-size': 1,
                        'icon-anchor': 'bottom-left',
                        'icon-offset': [-7, 0],
                    }
                });*/


                map.on('click', 'journey-points-layer', e => {
                    if (e.features.length) {
                        const feature = e.features[0];
                        // create popup node
                        const popupNode = document.createElement('div');
                        ReactDOM.render(<Popup feature={feature}/>, popupNode);
                        // set popup on map
                        popUpRef.current.setLngLat(feature.geometry.coordinates).setDOMContent(popupNode).addTo(map);

                    }
                });


                map.on('click', 'vehicle-points-layer', (e) => {
                    console.log(`vehicle-points-layer ${e}`);
                    console.log(e);
                    console.log(e.features[0].properties);
                    handleVehicle(e.features[0]);
                });


                map.on('click', 'events-layer', (e) => {
                    console.log(e);

                    console.log(e.features[0].properties);

                    console.log(JSON.parse(e.features[0].properties.alarmData));

                    e.features[0].properties.alarmData = JSON.parse(e.features[0].properties.alarmData);
                    e.features[0].properties.eventData = JSON.parse(e.features[0].properties.eventData);
                    e.features[0].properties.snapshots = JSON.parse(e.features[0].properties.snapshots);


                    handleJourney(e.features[0].properties.journeyId);
                    handleEvent(e.features[0].properties);

                });


                map.on('click', 'clusters', (e) => {
                    console.log('clicked cluster');
                    const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
                    const clusterId = features[0].properties.cluster_id;
                    console.log(clusterId);

                    const point_count = features[0].properties.point_count;
                    console.log(point_count);
                    const clusterSource = map.getSource(/* cluster layer data source id */'vehicle-points-data');

                    clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
                        console.log('getClusterLeaves', err, aFeatures);
                        console.log('zoom' + map.getZoom());
                        if (map.getZoom() === 16) {
                            this.openClusterPopup(aFeatures);
                        }
                    });

                    map.getSource('vehicle-points-data').getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom
                        });

                        console.log('zoom2: ' + map.getZoom());

                    });
                });










                map.addLayer({
                    id: 'clusters',
                    type: 'symbol',
                    source: 'vehicle-points-data',
                    filter: ['has', 'point_count'],

                    layout: {
                        'icon-image': 'cluster-marker',

                        'text-field': '{point_count_abbreviated}',
                        // 'icon-ignore-placement': true,

                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,

                        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        'icon-size': 0.9,
                        // 'icon-anchor': 'bottom-left',
                        // 'icon-offset': [-20, 0],
                        'text-anchor': 'bottom',
                        'text-offset': [0, 0.4],
                    }
                });


                map.addLayer({
                    id: 'line-layer',
                    type: 'line',
                    source: 'journeyLine',

                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                        visibility: 'visible'
                    },
                    paint: {
                        // 'line-color': '#d723d4',
                        'line-color': '#7318ec',

                        /*'line-offset': {    // works only for MAP SNAPPED!!
                            stops: [
                                [1, 0],
                                [14, 0],
                                [18, -5],
                                [22, -15]
                            ]
                        },*/

                        'line-width': {
                            base: 10,
                            stops: [
                                [1, 5],
                                [15, 12]
                            ]
                        },
                        'line-opacity': {
                            stops: [
                                [1, 1],
                                [14, 0.5],
                                [20, 0.0]
                            ]
                        },

                    }

                }, 'journey-points-layer');      // 'eventsLayer');


            });


        };

        if (!map) initializeMap({setMap, mapContainer});
    }, [map]);


    useEffect(() => {
        // need this to ensure that the map is loaded

        console.log('vehicle effect');

        if (map) {


            map.getSource('journey-points').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('journeyLine').setData({
                type: 'FeatureCollection',
                features: []
            });

            console.log(vehicles);

            if (vehicles === null ||  typeof vehicles.features === 'undefined' || vehicles.features.length === 0) {
                console.log('no data');
                return;
            } else {
                console.log('have data');
            }

            map.getSource('vehicle-points-data').setData(vehicles)

            let coords = [];
            vehicles.features.forEach((f) => {
                coords.push(f.geometry.coordinates);
            });

            // console.log(coords);

            console.log('fitMapBoundsToJourney: ');

            const bounds = coords.reduce(function (b, coord) {
                return b.extend(coord);
            }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

            console.log(bounds);
            let padding = {};

/*
            // TODO
            lat lng error
            map.fitBounds(bounds, {
                padding: {bottom: 100, left: 300, top: 100, right: 100}, maxZoom: 16
            });*/

            let circleData = {type: 'FeatureCollection', features: []}
            map.getSource('pink-circle-source').setData(circleData);


            let eventsData = {type: 'FeatureCollection', features: []}
            map.getSource('events-data').setData(eventsData);


        }
    }, [map, vehicles])


    useEffect(() => {
        // zoom map to flag

        console.log('zoom effect');

        if (map) {

            if (zoom !== null) {
                map.easeTo({center: zoom, zoom: 16, duration: 1000});
            }

        }
    }, [map, zoom])


    useEffect(() => {
        // need this to ensure that the map is loaded



        console.log('props.data.journey changed');
        if (map) {


            map.getSource('journey-points').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('journeyLine').setData({
                type: 'FeatureCollection',
                features: []
            });



            /*clear all vehicles*/
            map.getSource('vehicle-points-data').setData({
                type: 'FeatureCollection',
                features: []
            });

            map.getSource('events-data').setData(events);


            if (journey === null) {
                console.log('no jounrey');
                return;
            }

            // console.log(journey);
            map.getSource('journey-points').setData(journey);


            let coords = [];

            journey.features.forEach((f) => {
                coords.push(f.geometry.coordinates);
            });




            console.log('fitMapBoundsToJourney: ');


            /*let startFlagData = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: coords[0]
                        }
                    }
                ]
            }
            map.getSource('start-flag-source').setData(startFlagData);*/


            /*let finishFlagData = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'Point',
                            coordinates: coords[coords.length - 1]
                        }
                    }
                ]
            }
            map.getSource('finish-flag-source').setData(finishFlagData);*/




            ReactDOM.render(<EndMarker handleEndPic={handleEndPic} journey={journey} />, el);



            marker.setLngLat(coords[coords.length - 1]).addTo(map);
            // marker.setOffset([0,0]);
            // marker.setOffset([finishOffsetX,finishOffsetY]);




            ReactDOM.render(<StartMarker journey={journey} />, elStart);
            markerStart.setLngLat(coords[0]).addTo(map);








            const bounds = coords.reduce(function (b, coord) {
                return b.extend(coord);
            }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

            console.log(bounds);
            let padding = {};


            if (event === null) {

                let circleData = {type: 'FeatureCollection', features: []}
                map.getSource('pink-circle-source').setData(circleData);

                map.fitBounds(bounds, {
                    padding: {bottom: 150, left: 150, top: 250, right: 150}, maxZoom: 16
                });

            } else {



                const rect = document.getElementById('mapContainer').getBoundingClientRect();
                const viewportX = [rect.right][0];

                // const coords = [  this.vehicleEvent.lng ,   this.vehicleEvent.lat];

                map.flyTo({
                    center: [event.alarmData.statusData.longitude, event.alarmData.statusData.latitude],
                    offset: [(viewportX / 6), 0],
                    zoom: 15,
                    speed: 2
                });





/*                map.flyTo({
                    center: [event.alarmData.statusData.longitude, event.alarmData.statusData.latitude],
                    zoom: 16,
                    speed: 4,
                });*/


                let circleData = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [event.alarmData.statusData.longitude, event.alarmData.statusData.latitude]
                            }
                        }
                    ]
                }


                map.getSource('pink-circle-source').setData(circleData);


            }


            const coordsData = {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: coords
                }
            };


            map.getSource('journeyLine').setData(coordsData);

            map.resize();


        }

    }, [journey, event, events])



    useEffect(() => {

        //move map to end pic

        console.log('move map to end pic');
        if (map) {


           // hide marker
          marker.remove();


                const rect = document.getElementById('mapContainer').getBoundingClientRect();
                const viewportX = [rect.right][0];

                // const coords = [  this.vehicleEvent.lng ,   this.vehicleEvent.lat];



            if(endPic !== null) {
                map.flyTo({
                    center: endPic.features[endPic.features.length - 1].geometry.coordinates,
                    offset: [(viewportX / 6), 0],
                    zoom: 15,
                    speed: 2
                });


                let circleData = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: endPic.features[endPic.features.length - 1].geometry.coordinates
                            }
                        }
                    ]
                }


                map.getSource('pink-circle-source').setData(circleData);

            }





        }

    }, [endPic])







    useEffect(() => {

        //move map to end pic

        // console.log('sideBarWidthPx ' + sideBarWidthPx);
        if (map) {

            //setMap(map);
            //map.resize();
            window.setTimeout(()=>map.resize(), 50);
            window.setTimeout(()=>map.resize(), 100);
            window.setTimeout(()=>map.resize(), 150);
            window.setTimeout(()=>map.resize(), 200);
            window.setTimeout(()=>map.resize(), 250);
            window.setTimeout(()=>map.resize(), 300);

            const center = map.getCenter();

            map.flyTo({
                center: center,
                speed: 2
            });




        }

    }, [sideBarWidthPx])











    return (

        <div ref={el => (mapContainer.current = el)} style={styles}/>

    );
};

export default Map2;
