// import Image from "./Image";
import React from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import { Chart } from 'primereact/chart';
import {useEffect, useState} from "react";
import fetchImage from "../api/fetchImage";

import {API_URL} from '../Constants.js'

const divStyle = {
    color: 'purple'

};






const Event = ( {event} ) => {

    console.log(event);

    let speedArray = [];
    let directionArray = [];
    let impactArray = [];
    let gSensorXArray = [];
    let gSensorYArray = [];
    let gSensorZArray = [];

    const data = {
        labels: ['-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],

        //labels: ['February', 'March', 'April', 'May', 'June', 'July','bob'],
        datasets: [
            {
                label: 'Speed mph',
                data: [],
                fill: false,
                borderColor: '#69de1f',
                tension: 0.3
            },
            {
                label: 'gForce',
                data: [],
                fill: false,
                borderColor: '#565656',
                tension: 0.3
            },
            {
                label: 'Heading change',
                data: [],
                fill: false,
                borderColor: '#6cc0d9',
                tension: 0.3
            }
        ]
    };


    const data2 = {
        labels: ['-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],

        //labels: ['February', 'March', 'April', 'May', 'June', 'July','bob'],
        datasets: [
            {
                label: 'x',
                data: [],
                fill: false,
                borderColor: '#69de1f',
                tension: 0.3
            },
            {
                label: 'y',
                data: [],
                fill: false,
                borderColor: '#565656',
                tension: 0.3
            },
            {
                label: 'z',
                data: [],
                fill: false,
                borderColor: '#6cc0d9',
                tension: 0.3
            }
        ]
    };


    const angleDistance = (a, b) => {
        const c = Math.abs(a - b) % 360;       // This is either the distance or 360 - distance
        return c > 180 ? 360 - c : c;
    }

    if (event !== null) {

        if (typeof event.eventData !== 'undefined') {
            event.eventData.forEach((e, index) => {

                if (e.acquisitionTime !== '2048-00-00T00:00:00') {
                    speedArray.push(e.speed / 100 * 0.621371);
                    if (index + 1 < event.eventData.length) {

                        directionArray.push(angleDistance(e.direction, event.eventData[(index + 1)].direction));
                    }
                    impactArray.push(e.gSensorImpact);

                    gSensorXArray.push(e.gSensorX);
                    gSensorYArray.push(e.gSensorY);
                    gSensorZArray.push(e.gSensorZ);
                }


            });

            data.datasets[0].data = speedArray;
            data.datasets[1].data = impactArray;
            data.datasets[2].data = directionArray;

            data2.datasets[0].data = gSensorXArray;
            data2.datasets[1].data = gSensorYArray;
            data2.datasets[2].data = gSensorZArray;
        }

    }


   /* const imageBody = (rowData) => {
        return <img src={`https://myfleetlive.org:8000/angFetchSnapshot/${rowData.snapshots[0].file}_sm`}
                    onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                    alt={rowData.image} className="product-image" width="120px"/>
    }*/





    /******* Test for video or image with authorization  ****/
    const [testImage, setTestImage] = useState(null);

    useEffect(() => {

        fetchImage().then(r => {
            console.log(r);
            setTestImage(URL.createObjectURL(r));
        });
    }, []);







    return (
        <div style={divStyle}>


            <h2>Event</h2>

            {event &&

            <TabView>
                <TabPanel header="Snapshots">


                    <img src={`${API_URL}/angFetchSnapshot/${event.snapshots[0].file}_lrg`}
                         onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                         alt={event.image} className="product-image" width="100%"/>


                </TabPanel>


                <TabPanel header="Videos">

                    <video width="680" controls src={testImage}  />

                </TabPanel>

                <TabPanel header="Graph">

                    <Chart type="line" data={data}/>

                    <Chart type="line" data={data2}/>


                </TabPanel>
                <TabPanel header="Data">


                    <table className="table">

                        <tr>
                            <th>time</th>
                            <th>mph</th>
                            <th>direction</th>
                            <th>gSensorX</th>
                            <th>gSensorY</th>
                            <th>gSensorZ</th>
                            <th>gSensorTilt</th>
                            <th>gSensorImpact</th>
                        </tr>

                        {
                            event.eventData.map(el =>

                                <tr> {el.acquisitionTime}

                                    <td>
                                        {el.acquisitionTime}
                                    </td>
                                    <td>
                                        {(el.speed / 100 * 0.621371)}
                                    </td>
                                    <td>
                                        {el.direction}
                                    </td>
                                    <td>
                                        {el.gSensorX}
                                    </td>
                                    <td>
                                        {el.gSensorY}
                                    </td>
                                    <td>
                                        {el.gSensorZ}
                                    </td>
                                    <td>
                                        {el.gSensorTilt}
                                    </td>
                                    <td>
                                        {el.gSensorImpact}
                                    </td>

                                </tr>
                            )
                        }


                    </table>


                    <pre>{JSON.stringify(event.analysis, null, 2)}</pre>


                </TabPanel>
            </TabView>


            }


        </div>


    );
}


export default Event;
