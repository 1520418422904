import React from 'react';

import useToken from '../login/useToken.js';

import {API_URL} from '../Constants.js'


const FetchJourney = (id) => {





    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  },
        // body: JSON.stringify(data)
    };








    return fetch(API_URL + '/f-journey/' + id, requestOptions)
        .then(
            (response) => {
                console.log(response.status);

                if(response.status === 403) {
                    localStorage.removeItem('token');
                }

                // response.status
                return response.json()
            })
        .then((data) => {
                console.log(data);


                const features = [];


                if (typeof data.tracks !== 'undefined') {

                    if (data.tracks !== null) {

                        for (let i = 0; i < data.tracks.length; i++) {


                            const feature = {
                                type: 'Feature',
                                properties: {
                                    head: data.tracks[i].head,
                                    // colour: '#ef00c6',
                                    colour: '#000000',
                                    speed: Math.round(data.tracks[i].speed / 100 * 0.621371),    // to mph,
                                    // distance: cumDistance,
                                    time: data.tracks[i].at,
                                    gpsAcc: data.tracks[i].gpsAcc,
                                    sigInt: data.tracks[i].sigInt,
                                    netType: data.tracks[i].netType,
                                    maxspeed: data.tracks[i].maxspeed,
                                    storeForward: data.tracks[i].storeForward,
                                    alarm: data.tracks[i].alarm,

                                },
                                geometry: {
                                    type: 'Point',
                                    coordinates: [data.tracks[i].lng, data.tracks[i].lat]
                                }

                            };


                            features.push(feature);

                        }
                    }
                }

                // data.startTime = data.tracks[0].at;

                delete(data.tracks);
                delete(data.events);
                /////////////////////////////////////////////////////////////////

                return Promise.resolve({
                    type: 'FeatureCollection',
                    details: data,
                    features: features,
                });


            }
        );
}


export default FetchJourney;
