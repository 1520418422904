import CustSVG from '../svgs/circle.svg';
import CustSVG2 from '../svgs/circle2.svg';
import PinAcceleration from '../svgs/pin-acceleration.svg';
import Arrow from '../svgs/licence-plate-arrow.png';

import licencePlateCluster from '../svgs/licence-plate-cluster.png';
import licencePlate from '../svgs/licence-plate-1.png';

const CustomIcons = [
    {
        src: CustSVG,
        name: 'circle-red-svg',
        width: 40,
        height: 40,
        sdf: false
    },

    {
        src: CustSVG2,
        name: 'circle-svg',
        width: 40,
        height: 40,
        sdf: false
    },



    {
        src: CustSVG,
        name: 'circle-big-svg',
        width: 60,
        height: 60,
        sdf: true
    },

    {
        src: PinAcceleration,
        name: 'pin-acceleration',
        width: 30,
        height: 30,
        sdf: true
    },


    {
        src: Arrow,
        name: 'lpArrow',
        width: 30,
        height: 30,
        sdf: true
    },

    {
        src: licencePlateCluster,
        name: 'cluster-marker',
        width: 47,
        height: 37,
        sdf: false
    },

    {
        src: licencePlate,
        name: 'whitePlate',
        width: 100,
        height: 26,
        sdf: false
    }


];

export default CustomIcons;


