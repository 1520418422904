/* src/components/EndMarker.js */
import React from 'react';
import ImageMfl from "./ImageMfl";
import {FaFlagCheckered} from "react-icons/fa";




const arrowDown ={
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #000',
    position: 'absolute',
    top: '140px',
    left: '60px'
}


const arrowUp ={
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid #000',
    position: 'absolute',
    top: '-10px',
    left: '60px'
}

const arrowLeft ={
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight: '10px solid #000',
    position: 'absolute',
    top: '60px',
    left: '-10px'
}

const arrowRight ={
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderLeft: '10px solid #000',
    position: 'absolute',
    top: '60px',
    left: '140px'
}

const markerHolder ={
    padding: '10px',
    position: 'relative',
}


const EndMarker = ({ journey, handleEndPic  }) => {

   if(journey === null) {
       return null
   }



    if(journey.details.endAddress === null) {
        journey.details.endAddress = {
            place: 'dsfsdf',
            town: 'fdgdfg'
        }
    }




    let headings = [];

    journey.features.forEach((f) => {
        headings.push(f.properties.head);
    });



    // console.log(headings);


    const averageLastTenHeadings = (headings.slice(-10).reduce((acc, val) => acc + val)) / 10;

    // console.log(averageLastTenHeadings);

    let finishOffsetX = ((Math.sin(   averageLastTenHeadings * Math.PI/180   )) );
    // console.log('x: ' + finishOffsetX);
    let finishOffsetY= ((Math.cos(   averageLastTenHeadings * Math.PI/180   )) );
    // console.log('y: ' + finishOffsetY);


    let arrowStyle = arrowDown;


    if(Math.abs(finishOffsetX) > Math.abs(finishOffsetY)) {

        finishOffsetX = 0;

        if(finishOffsetY > 0) {
            finishOffsetY = -150
            arrowStyle = arrowDown;
        }  else {
            finishOffsetY = 160
            arrowStyle = arrowUp;
        }


    } else {

        finishOffsetY = 0;

        if(finishOffsetX > 0) {
            finishOffsetX = 170
            arrowStyle = arrowLeft;
        }  else {
            finishOffsetX = -150
            arrowStyle = arrowRight;
        }

    }









    const markerStyle = {
        marginLeft: finishOffsetX,
        marginTop: finishOffsetY,
        background: 'rgba(255,255,255,0.75)',
        border: 'solid 1px #cccccc'
    }

    // console.log(`arrowStyle = ${arrowStyle}`);
   //  console.log(arrowStyle);


    const onEndPicSelect = (e) => {
       // console.log(e);
        handleEndPic(e);
    }


    return (
        <div>

        {journey.details.completed ?


                <div className="marker" style={markerStyle}    onClick={() => onEndPicSelect(journey)} >

                    <div style={markerHolder}>

                        <ImageMfl file={journey.details.endJpeg}  />
                        <div>

                            <FaFlagCheckered />

                            {` ${(new Date(journey.details.endTime)).toLocaleString().slice(11, 17)} ${journey.details.endAddress.place},
                 ${journey.details.endAddress.town} ${parseFloat(journey.details.distance).toFixed(1)} miles
                 `}


                        </div>


                        <div style={arrowStyle}/>


                    </div>

                    {/*{journey.details.duration}&nbsp;*/}


                </div>


                : null }


        </div>



    )


};

export default EndMarker;
