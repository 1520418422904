import { Button } from "primereact/button"
import ImageMfl from "./ImageMfl";
import React, {useEffect, useState, createRef, useRef, useLayoutEffect} from "react";
import './ListJourneys.css';
import Icon from "./Icon";

import ResizeObserver from 'resize-observer-polyfill';


const divStyle2 = {
    fill: 'rgba(115,24,236,0.55)',
    stroke: '#ffffff',
    background: '#ffffff'
};

const divStyle = {

    stroke: '#ffffff'
};

const textStyle = {
    fill: '#f8f8f8',
}

// className={this.state.selectedItemIndex== dragonball.id? 'hover': null}








const ListJourneys = ( {journeys, journey, handleJourney, events, handleEvent} ) => {



   //console.log(document.getElementById('mapContainer').getBoundingClientRect().width);




    const [viewportX, setViewportX] = useState(document.getElementById('mapContainer').getBoundingClientRect().width);
    // const [viewportX, setViewportX] = useState();

    const [width, setWidth] = useState(0);

    const [j, setJ] = useState(null);

    // const divRef = createRef();

    const ref = useRef(false)

    const resizeObserverRef = useRef(null);







    const drawTimeLine = (lastHourAdd) => {
        const firstTime = new Date(journeys[0].startTime);
        firstTime.setMinutes(0);
        firstTime.setSeconds(0);
        const lastTime = new Date(journeys[journeys.length -1].lastTrack.at);
        lastTime.setHours(lastTime.getHours() + lastHourAdd);
        lastTime.setMinutes(0);
        lastTime.setSeconds(0);

        const rect = document.getElementById('mapContainer').getBoundingClientRect();

        //console.log(rect.width);


        //console.log(  `lastTime ${lastTime} firstTime  ${firstTime}  viewportX  ${viewportX}` )

        const secondsPixelRatio = ((lastTime - firstTime) /viewportX /1000);    // /milliseconds 1000 length bar

        //console.log(secondsPixelRatio);





        journeys.forEach(j => {
            const st = new Date(j.startTime);
            const et = new Date(j.lastTrack.at);
            // console.log((st - firstTime) / 1000  / secondsPixelRatio);
            j.startPixel = (st - firstTime) / 1000  / secondsPixelRatio;


            const durationSeconds = ((et - st) / 1000);



            j.endPixel = durationSeconds / secondsPixelRatio

            //  console.log(j.endPixel);

            if(j.endPixel < 10) {
                j.endPixel = 10;
            }
            // console.log(`st ${st} et ${et}  durationSeconds  ${durationSeconds}   j.startPixel ${j.startPixel}    j.endPixel ${j.endPixel} `);

        })




        const tt = new Date(firstTime);
        let thisHour = 0;
        while (tt < lastTime) {
            // console.log(tt);
            // console.log(firstTime);
            // console.log(   (tt - firstTime) / 1000  / secondsPixelRatio    );
            const pixelHour = (tt - firstTime) / 1000  / secondsPixelRatio;
            const hour = new Date(tt);

            if(hour.getHours() > 12) {
                thisHour = (hour.getHours() - 12) + 'pm'
            } else {
                thisHour = hour.getHours() + 'am'
            }

            hourArray.push({
                pixelHour: pixelHour,
                thisHour: thisHour
            })
            tt.setHours(tt.getHours() + 1);
        }







        journeys.forEach(d => {

            if(typeof d.startTime === 'undefined'  ) {
                d.startTime = 'xxxxxxxxxxxx';
            }

        });



        /*if(typeof events.features !== 'undefined') {

            const width = 64;
            events.features.forEach(e => {

                const st = new Date(e.properties.startTime);
                // console.log((st - firstTime) / 1000  / secondsPixelRatio);
                e.startPixel = (st - firstTime) / 1000  / secondsPixelRatio;
                e.actualPixel = e.startPixel;
                // console.log(e.startPixel);
                // console.log(st);
                if (e.startPixel < prevPixel + (width /2) ){
                    e.startPixel = prevPixel +  (width /2);
                } else {
                    //
                }
                prevPixel = e.startPixel;



                // console.log(e.properties.analysis.eventType);
                // console.log(e.properties.analysis.severity);

                if(e.properties.analysis.severity === 'moderate') {
                    e.iconColour = '#B81AAA';
                } else {
                    e.iconColour = 'red';
                }



                if(e.properties.analysis.eventType === 'acceleration'){
                    e.icon = '/svgs/pin-acceleration.svg#acceleration_Image';
                } else if (e.properties.analysis.eventType === 'braking') {
                    e.icon = '/svgs/pin-brake.svg#braking_Image';
                } else if (e.properties.analysis.eventType === 'bump') {
                    e.icon = '/svgs/pin-shock.svg#shock';
                } else {
                    e.icon = '/svgs/pin-turning.svg#cornering_Image';
                }





            });
        }*/

    }



    const journeyMouseOver = (j)=> {
        // console.log('journeyMouseOver');
        setJ(j);
    }

    const journeyMouseOut = ()=> {
        // console.log('journeyMouseOut');
        setJ(null);
    }




    useEffect(() => {
        resizeObserverRef.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                // setdDimensions({ width, height });

                setViewportX(width);
                setWidth(width);

                //console.log({ width, height });




            });
        });
        if (ref.current) resizeObserverRef.current.observe(ref.current);
        return () => {
            if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
        };
    }, [ref, journey]);












    if(journeys === null ) {
        return null
    }

    if(typeof journeys === 'undefined') {
        return null
    }

    if( journeys.length < 1) {
        return null
    }

    if( journey === null) {
        return null;
    }


    const hourArray = [];

    let prevPixel = 0;





    const ChangeColour = (e) => {

        console.log(e.iconColour);
        e.iconColour = '#000000';
        console.log(e.iconColour);
    }


    drawTimeLine(2);









    return (
        <div ref={ref}>









           {/* <pre>{JSON.stringify(events.features.length, null, 2)}</pre>*/}


            { journeys !== null &&





            <svg   width="100%" height="50" >

                <rect x="0" y="0" width={viewportX} height="50" fill="#ffffff" />

                <rect x="0" y="20" width={viewportX} height="20" fill="#F8F9FA" />

                {hourArray.map((d, index) => (
                    <g key={index}>



                        <line x1={d.pixelHour} y1="0" x2={d.pixelHour} y2="37" stroke="#B9B9B0"/>
                        <text x={d.pixelHour + 2} y="13"   fontSize="14px">{d.thisHour}</text>

                    </g>

                ))}



                {journeys.map((d, index) => (




                   <g className="journeyBlock" onClick={() => handleJourney(d._id)}  onMouseEnter={() => journeyMouseOver(d)}   onMouseLeave={() => journeyMouseOut()}
                      key={index} cursor='pointer'>




                        <rect x={d.startPixel} y="20" width={d.endPixel} height="20" rx="8" ry="8"
                              style={d._id === journey.details._id ? divStyle2: divStyle}> </rect>


                       {/*<text x={d.startPixel + 3} y="33"  style={textStyle} fontSize="16px">{d.startTime.substr(d.startTime.length - 8)}</text>*/}

                       {/*<line x1={index * 180} y1="0" x2={index * 180} y2="32" style={d._id === journey.details._id ? divStyle2: divStyle} />*/}

                       {/*<image href="mdn_logo_only_color.png" height="100" width="60"/>*/}




                    </g>

                ))}






                {/*                {events.features.map((e, index) => (
                    <g  onClick={() => {  handleJourney(e.properties.journeyId);   handleEvent(e.properties)}}
                        cursor='pointer'    className="icon">



                        handleEvent(e.features[0].properties);


                        <line x1={e.actualPixel} y1="25" x2={e.startPixel} y2="40" stroke="#000000"/>
                        <circle cx={e.startPixel } cy="49" r="14"  fill="#F8F2F8"/>
                        <svg width="30" height="30"   x={e.startPixel - 15} y="35" viewBox="0 0 500 500">
                        <use  href={e.icon} fill={e.iconColour}   style={{shapeRendering: 'geometricPrecision'}}    />
                        </svg>


                        <Icon e={e}/>

                    </g>

                ))}*/}








            </svg>}



            {j !== null &&




            <div className='journeyToolTip' style={{
                top: '70px',  left: j.startPixel}}>




                {(new Date(j.startTime)).toLocaleString().slice(11, 17)} &nbsp;

                {j.startAddress && j.startAddress.town} &nbsp;


                {(new Date(j.lastTime)).toLocaleString().slice(11, 17)} &nbsp;

                 {j.endAddress && j.endAddress.town} &nbsp;




            </div>






            }




        </div>


    )}

export default ListJourneys;


// https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element
