import {API_URL} from '../Constants.js'



const fetchImage = () => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  },
    };


    return fetch(API_URL + '/view-video/1000234_20211028110052-20211028110110_1_20_h265', requestOptions)
        .then((response) => response.blob())
        .then((data) => {
                console.log(data);


                return Promise.resolve(
                    data
                );


            }
        );

}


export default fetchImage;

// https://myfleetlive.org:8000/angFetchVidThumb/1000040_20210927112234-20210927112254_1_20

// https://myfleetlive.org:8000/view-snapshot/1000040_20211105084345_1_sm


// https://myfleetlive.org:8000/view-video/1000234_20211028110052-20211028110110_1_20_h265
