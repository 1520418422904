import React from 'react';
import {API_URL} from '../Constants.js'
const ImageMfl = ({file, svg}) => {



const mph = {
    fontSize: '11px',
    fontWeight: 'normal',
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fill: '#1b6082'
}

const small =  {
        fontSize: '15px',
        fontWeight: 'bold',
        textAnchor: 'middle',
        fontFamily: 'Arial Black'
    }


    const eventSpeed = {
        position: 'absolute',
        top: '25px',
        left: '3px'
    }


    if (svg) {

        return (

            <div style={{position: 'relative'}}>


                <img src={`${API_URL}/angFetchSnapshot/${file}_sm`}
                     onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                     alt={''} className="product-image" width="116px" height="60px"/>


                {/*<img className="eventPin" src="../../assets/img/pin-brake.svg"/>*/}
                <svg style={eventSpeed} id="circle" xmlns="http://www.w3.org/2000/svg" width="130"
                     viewBox="0 0 130 30">
                    <defs>
                        {/*<style>

                }

                }
                </style>*/}
                    </defs>
                    <circle cx="15" cy="15" r="13" stroke="red" strokeWidth="3" fill="white"/>
                    <text style={small} x="15" y="20"> 60</text>
                    <text style={mph} x="42" y="20">mph</text>
                </svg>


            </div>


        );


    } else {

        return (

            <img src={`${API_URL}/angFetchSnapshot/${file}_sm`}
                 onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                 alt={''} className="product-image" width="116px" height="60px"/>


        );


    }


}


export default ImageMfl;
