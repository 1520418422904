import {Button} from "primereact/button"
import {ScrollPanel} from "primereact/scrollpanel";

import './ListVehicles.css';
import DatePicker from "./DatePicker";
import React, {useEffect, useRef, useState} from "react";

import {InputText} from 'primereact/inputtext';

import {Chip} from 'primereact/chip';
import ResizeObserver from "resize-observer-polyfill";

import { Dropdown } from 'primereact/dropdown';

import useToken from '../login/useToken.js';

// import VehicleListFiltered from './VehicleListFiltered';

const selectedStyle = {
    background: '#eeeeee',
    color: '#000000'
}

const selectedStyle2 = {
    background: '#6b6b6d'
}


const vStyle = {
    marginBottom: '3px',
    paddingBottom: '3px',
    marginTop: '3px',
    paddingTop: '3px',
    position: 'relative',
    cursor: 'pointer',
    color: '#000000'
}




const svgCont = {
    position: 'absolute'
}


const dStyle = {
    padding: '0.3rem 0.3rem',
    margin: '3px',
    background: '#dbd9d9',
    color: '#404040'
}



const tStyle = {
    padding: '2px 2px',
    margin: '3px',
    background: '#dbd9d9',
    color: '#404040',
    fontSize: '11px'
}


const driving = {
    fill: 'green'
}

const idling = {
    fill: 'orange'
}

const parked = {
    visibility: 'hidden'
}

const offline = {
    fill: 'grey'
}


const regStyle = {
    fontcolor: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: '35px'
}

const vehicleDetail = {
    fontcolor: 'black',
    fontSize: '12px',
    marginLeft: '35px'
}

const a = new Set(['driving', 'idling', 'parked']);


const cities = [
    { fleet: 'fleet1', code: 'NY' },
    { fleet: 'fleet2', code: 'RM' },
    { fleet: 'fleet3', code: 'LDN' },

];






const ListVehicles = ({vehicles, vehicle, handleVehicle, handleVehicles, date, handleDate, handleVehicleFilter,
                          chips, addChip, removeChip}) => {

    // console.log(vehicles);


    const { token, setToken } = useToken();


    /*So style can work on selected*/
    if (vehicle === null) {
        vehicle = {properties: {dn: null}}
    }


    // let chips =  new Set(['sar', 'sar 5']);


    const [filteredVehicles, setFilteredVehicles] = useState([]);

    const [value, setValue] = useState('');


    // const [chips, setChips] = useState(chips2);    // useState(new Set([]));

    const [viewportY, setViewportY] = useState(0);

    const [viewportYTop, setViewportYTop] = useState(10);

    const ref = useRef(null)
    const refTop = useRef(null)
    const resizeObserverRef = useRef(null);
    const resizeObserverRefTop = useRef(null);


    useEffect(() => {


        console.log('useEffect change');
        if (vehicles !== null) {
            if (typeof vehicles.features !== 'undefined') {
                setFilteredVehicles(vehicles.features);
            }
        }


    }, [vehicles]);


    useEffect(() => {

        resizeObserverRef.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const {width, height} = entry.contentRect;
                // setdDimensions({ width, height });

                setViewportY(height);
            });
        });
        if (ref.current) resizeObserverRef.current.observe(ref.current);
        return () => {
            if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
        };


    }, [ref]);


    useEffect(() => {
        resizeObserverRefTop.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const {width, height} = entry.contentRect;

                setViewportYTop(height);
            });
        });

        if (refTop.current) resizeObserverRefTop.current.observe(refTop.current);
        return () => {
            if (resizeObserverRefTop.current) resizeObserverRefTop.current.disconnect();
        };

    }, [refTop]);






    useEffect(() => {
        console.log('chips changed');
        console.log(chips);


        if(vehicles) {
            setValue('');
            setFilteredVehicles(simpleVehicleFilter(''));
        }

    }, [chips, vehicles]);








    // let filteredVehicles = vehicles

    const testButton = () => {

        console.log(updateFilteredVehicles());

        // setFilteredVehicles(updateFilteredVehicles());
    }


    const testButton2 = (v) => {

        console.log(value);
        setValue(v)

        setFilteredVehicles(simpleVehicleFilter(v));
    }








    const toggleDrivingState = (s) => {

        console.log('toggleDrivingState ' + s);
        if (a.has(s)) {
            console.log('a has s');
            a.delete(s);
        } else {
            a.add(s)
        }

        console.log(a);

        setFilteredVehicles(simpleVehicleFilter(''));

    }


    const simpleVehicleFilter = (v) => {

        let f = v;



        // const a = new Set(['driving']);

        let filteredVehicles2 = vehicles.features.filter(v => {

            if (!a.has(v.properties.drivingStatus)) {
                return false
            }


            const foundChip = [...chips].filter(m => {
                // return v.properties.registration.toLowerCase().indexOf(c.toLowerCase()) >= 0
                return matchVehicle(v, m);
            });
            if (foundChip.length !== chips.size && chips.size > 0) {
                return false
            }
            // console.log('foundChip.length: ' + foundChip.length);


            // return v.properties.registration.toLowerCase().indexOf(f.toLowerCase()) >= 0;
            return matchVehicle(v, f);


        });
        handleVehicleFilter(filteredVehicles2);
        return (filteredVehicles2);

    }


    const matchVehicle = (v, m) => {

        // console.log('match vehicle');

        if (typeof v.properties.tags === 'undefined') {
            v.properties.tags = [];
        }

        // console.log(v.properties.tags)
        const foundTag = v.properties.tags.filter(t => {
            return t.toLowerCase().indexOf(m.toLowerCase()) >= 0
        })
        // console.log(foundTag.length);


        return (v.properties.registration.toLowerCase().indexOf(m.toLowerCase()) >= 0 ||
            v.properties.driver.toLowerCase().indexOf(m.toLowerCase()) >= 0 ||
            foundTag.length >= 1
        );
    }


    const updateFilteredVehicles = () => {

        //setFilteredVehicles(null);
        // return;


        if (vehicles === null) {
            return null;
        }


        let filteredVehicles2 = vehicles.features.filter(v => {

            if (chips.size === 0) {
                return true;
            }

            const a = new Set(['driving', 'idling', 'parked']);
            // const b = new Set(chips);
            const intersection = new Set(
                [...a].filter(z => chips.has(z)));


            /***** if size is 0 no status tag selected so make true *****/
            const ds = intersection.has(v.properties.drivingStatus) || intersection.size === 0;


            const testTags = function (test) {
                let x = false;
                if (typeof v.properties.tags !== 'undefined') {
                    v.properties.tags.forEach(function (t) {
                        if (t.toLowerCase().indexOf(test.toLowerCase()) === 0) {
                            x = true;
                            return x;
                        }
                    });
                }
                return x;
            };


            // console.log('testTags: ' + testTags() );


            const chipMatchArray = [];
            chips.forEach(f => {

                // console.log(f);

                // console.log(v.properties.registration.toLowerCase().indexOf(f.toLowerCase()));

                chipMatchArray.push(
                    (v.properties.registration.toLowerCase().indexOf(f.toLowerCase()) >= 0
                        //  || (v.properties.driver.toLowerCase().indexOf(f.toLowerCase()) === 0)
                        //  || (v.properties.town.toLowerCase().indexOf(f.toLowerCase()) === 0)
                        //  || (f === 'driving') || (f === 'parked') || (f === 'offline') || (f === 'idling')
                        //  || testTags(f)
                    )
                );

            });


            // console.log(chipMatchArray);


            const checker = arr => arr.every(Boolean);

            const r = ds && checker(chipMatchArray);

            console.log(r);

            return (r);


        });

        console.log(filteredVehicles2);
        return (filteredVehicles2);

    }

    //


    //


    return (

        <div style={{height: '100vh'}}>

            <div ref={refTop} style={{padding: '10px',  background: '#F0F1F2', borderBottom: 'solid 1px #E4E4E4'}}>


                <div id="vehicleFilters">







                    <DatePicker date={date} handleDate={handleDate}/>


                    <button   onClick={() => handleDate(date, 1)} >forward day</button>
                    <button   onClick={() => handleDate(date, -1)}>back day</button>


                    <br/>

                    {/* <Chip style={{cursor: 'pointer'}} label="Driving" icon="pi pi-check" onClick={() => toggleDrivingState('driving')} />
            <Chip label="Idling" icon="pi pi-check" />
            <Chip label="Parked" icon="pi pi-check" />*/}


                    <Button style={dStyle} icon={a.has('driving') ? 'pi pi-check' : 'pi pi-times'} label='driving'
                            onClick={() => toggleDrivingState('driving')}/>

                    <Button icon={a.has('idling') ? 'pi pi-check' : 'pi pi-times'} label='idling'
                            style={dStyle} onClick={() => toggleDrivingState('idling')}/>
                    <Button icon={a.has('parked') ? 'pi pi-check' : 'pi pi-times'} label='parked'
                            style={dStyle} onClick={() => toggleDrivingState('parked')}/>


                    <div>



                        {[...chips].map((c, index) => (
                            <Chip key={c} label={c} className="activeChips" removable onRemove={() => removeChip(c)}/>
                        ))}
                    </div>

                    <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText value={value}  placeholder="Search" onChange={(e) => testButton2(e.target.value)}
                               onKeyPress={(e) => (e.key === 'Enter' ? addChip(e.target.value) : null)} />
                    </span>


                    {token.result.fleets &&

                    <Dropdown options={token.result.fleets} placeholder="Fleets"/>
                    }


                    {/*<button onClick={handleVehicles.bind(this, 101)}> All vehicles</button>*/}

                    {/*<button onClick={() => testButton()}> filter test vehicles</button>*/}


                    {/*<pre>{JSON.stringify(vehicles.features[0], null, 2)}</pre>*/}


                </div>




            </div>

            <div ref={ref} style={{height: '100vh'}}>


                {/*130 from header and footer measures*/}
                <ScrollPanel style={{width: '100%',marginTop: '10px' , marginBottom: '10px' , height: viewportY - viewportYTop - 130}} className="custom">

                    <p>number = {filteredVehicles.length}</p>

                    {filteredVehicles !== null && <div>


                        {filteredVehicles.map((d, index) => (

                            <div className="vehicleButton" style={vStyle} key={index}>


                                <div className="p-button-secondary p-button-text"
                                     style={d.properties.dn === vehicle.properties.dn ? selectedStyle : null}
                                     onClick={() => handleVehicle(d)}
                                >


                                    <svg width="30" viewBox="0 0 50 50" style={svgCont}>
                                        <circle cx="25" cy="25" r="16" stroke="#D9D9D9" fill="#ffffff"/>
                                        <g
                                            transform={`rotate(${d.properties.lastLocation.head} 25 25)`}>
                                            <polyline points="25 0 7 44 25 30 43 44"
                                                      style={eval(d.properties.drivingStatus)}/>


                                        </g>

                                    </svg>


                                    <div><span style={regStyle}>{d.properties.registration}</span> {d.properties.driver}
                                    </div>

                                    <div
                                        style={vehicleDetail}> {new Date(d.properties.lastLocation.at).toLocaleString()}</div>
                                    <div style={vehicleDetail}>
                                        <strong> {(d.properties.lastLocation.speed / 100 * 0.62137119223733).toFixed(1)}</strong> mph
                                        location
                                    </div>

                                    <div style={vehicleDetail}>

                                        {d.properties.tags && d.properties.tags.map(t => (



                                            <Button style={tStyle} key={t} label={t} className="p-mb-2" onClick = {(e) => (addChip(t))}   />


                                            // addChip(e.target.value)


                                        ))}

                                    </div>


                                </div>
                            </div>

                        ))}


                    </div>}

                </ScrollPanel>

            </div>


        </div>


    )
};

export default ListVehicles;
