import React, {createRef, useEffect, useRef, useState} from "react";
import {API_URL} from '../Constants.js'
import Map2 from "./Map2";
import fetchVehicles from "../api/fetchVehicles";
import ListVehicles from "./ListVehicles";
import ListEvents from "./ListEvents";
import ListEventsVertical from "./ListEventsVertical";
import DatePicker from "./DatePicker";


import ListJourneys from "./ListJourneys";
import fetchJourneys from "../api/fetchJourneys";
import FetchJourney from "../api/fetchJourney";  //list

import {Splitter, SplitterPanel} from 'primereact/splitter';
import fetchEvents from "../api/fetchEvents";
import {OverlayPanel} from 'primereact/overlaypanel';
import {Button} from "primereact/button"

import {Dialog} from 'primereact/dialog';
import Event from "./Event";
import Journey from "./Journey";

import './MapParent.css';
import {ScrollPanel} from "primereact/scrollpanel";
import fetchImage from "../api/fetchImage";
import VehicleDetail from "./VehicleDetail";


const MapParent = () => {


    const [vehicles, setVehicles] = useState(null);
    const [vehiclesFilter, setVehiclesFilter] = useState(null);

    const [vehicle, setVehicle] = useState(null);

    const [journeys, setJourneys] = useState(null);

    const [journey, setJourney] = useState(null);

    const [events, setEvents] = useState([]);

    const [event, setEvent] = useState(null);

    const [endPic, setEndPic] = useState(null);

    const [date, setDate] = useState(new Date());

    const [zoom, setZoom] = useState(null);

    const [sideBarWidthPx, setSideBarWidthPx] = useState('0px');


    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasicPic, setDisplayBasicPic] = useState(false);

    const [chips, setChips] = useState(new Set([]));



    const addChip = (v) => {
        console.log(typeof chips2);
        setChips(new Set([...chips, v]));
    }


    const removeChip = (v) => {
        chips.delete(v)
        setChips(new Set([...chips]));
    }






    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
        'displayBasicPic': setDisplayBasicPic,
    }


    // const op = useRef(null);

    const handleJourney = (id) => {
        console.log(`handleJourney ${id} `);
        FetchJourney(id).then(r => setJourney(r));
        setEvent(null);//asynchronous
    };


    const handleDate = (e, dayShift = 0) => {

        console.log(e);
        console.log(dayShift);

        let nd = new Date(e);
        nd.setDate(e.getDate() + dayShift)

        setDate(nd);

        console.log(date);

    }

    const handleVehicle = (v) => {
        setVehicle(v);//asynchronous

    }


    const handleVehicleFilter = (vf) => {
        console.log(vf.length);
        vf.features = vf;
        vf.type = 'FeatureCollection';
        setVehiclesFilter(vf);//asynchronous
    }



    const handleEvent = (e) => {
        console.log(e);
        setEvent(e);//asynchronous
        onDialogClick('displayBasic')
    }


    const handleEndPic = (e) => {
        console.log(e);
        setEndPic(e);//asynchronous
        onDialogClick('displayBasicPic')
    }



    const onDialogClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const sideBarWidth = {
        marginLeft: sideBarWidthPx,
        transition: 'margin-left 0.3s'
    }

    const changeSideBarWidth = () => {

        console.log(sideBarWidthPx);

        if(sideBarWidthPx === '-300px') {
            setSideBarWidthPx('0px');
        } else {
            setSideBarWidthPx('-300px');
        }


    }


    useEffect(() => {
        console.log(date);
        console.log(`useEffect ${date}  ${vehicle}`);






        const dateString = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + date.getDate()).slice(-2)
        ].join('-');

        // date.setDate( date.getDate() + 1 );
        // console.log(date.getDate());
        // console.log(  ('0' + date.getDate() + 1)  );
        // console.log(  ('0' + date.getDate() + 1).slice(-2)  );


        console.log(date.toString());

        const nextDateString = [
            date.getFullYear(),
            ('0' + (date.getMonth() + 1)).slice(-2),
            ('0' + (date.getDate() + 1)).slice(-2)
        ].join('-');

        console.log('dString : ' + dateString);
        console.log('nextDateString : ' + nextDateString);


        if(vehicle !== null) {
            fetchJourneys(vehicle.properties.dn, dateString).then(
                r => {
                    if (r) {
                        console.log(r);

                        setJourneys(r);
                        if (typeof r[0] !== 'undefined') {
                            handleJourney(r[0]._id);
                        } else {
                            setJourneys(null);
                            setJourney(null);
                        }

                    } else {
                        setJourneys(null);
                        setJourney(null);
                    }
                }
            );

            const data = {
                "dn": vehicle.properties.dn,
                "startTime": dateString,
                "endTime": nextDateString
            }

            fetchEvents(data).then(r => setEvents(r));
        }





    }, [date, vehicle]);


    //let myData = {journey: null, data: null, journeys: null};

    const handleVehicles = async (e) => {

        setVehicles(await fetchVehicles());


        console.log(vehicles);
    }

    useEffect(() => {
        console.log('use effect running');
        fetchVehicles().then(r => {setVehicles(r); setVehiclesFilter(r)});

        fetchJourneys('1000234').then(r => setJourneys(r));

        const data = {
            "dn": "1000234",
            "startTime": "2021-10-06",
            "endTime": "2021-10-07"
        }

        fetchEvents(data).then(r => setEvents(r));

    }, []);


    return (



        <div className="container">












            <aside id="sidebar" className="container__sidebar" style={sideBarWidth}>


                <button className="sideBarButton"  style={{position: 'relative',  left: '30px', bottom: '-100px', zIndex: '200'}}
                        onClick={() => { changeSideBarWidth()  }}>


                    {sideBarWidthPx === '-300px' && '>'}
                    {sideBarWidthPx !== '-300px' && '<'}

                </button>




                <ListVehicles vehicles={vehicles} vehicle={vehicle} handleVehicle={handleVehicle} handleVehicles={handleVehicles}
                              date={date} handleDate={handleDate}   handleVehicleFilter={handleVehicleFilter}
                              chips={chips}   addChip={addChip} removeChip={removeChip}/>
            </aside>


            <main className="container__main" id="container__main" >






                <Dialog visible={displayBasic} position={'left'} style={{width: '50vw'}}
                        onHide={() => onHide('displayBasic')}>

                    <Event event={event}/>

                </Dialog>


                <Dialog visible={displayBasicPic} position={'left'} style={{width: '50vw'}}
                        onHide={() => onHide('displayBasicPic')}>

                    {/*<p>More details</p>*/}



                   {/* <pre>{JSON.stringify(endPic, null, 2)}</pre>*/}



                    {endPic &&

                        <div>

                    <img src={`${API_URL}/angFetchSnapshot/${endPic.details.endJpeg}_lrg`}
                         onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                         alt={endPic.details.endJpeg} className="product-image" width="100%"/>

                    <div>



                        Distance: {endPic.details.distance} <br />
                        Duration: {endPic.details.duration}<br />

                        Start:
                        {endPic.details.startTime}
                        {endPic.details.startAddress.place}
                        {endPic.details.startAddress.town}
                        {endPic.details.startAddress.address}<br />

                        End:
                        {endPic.details.endTime}
                        {endPic.details.endAddress.place}
                        {endPic.details.endAddress.town}
                        {endPic.details.endAddress.address}<br />










                        </div>
                        </div>

                    }


                </Dialog>
















                <div className="mapContainer" id="mapContainer" >




                    <div className="aboveMapContainer" >



                        {vehicle &&
                        <VehicleDetail vehicle={vehicle}  chips={chips}   addChip={addChip} />
                        }


                        {vehicle &&


                        <ListJourneys  journeys={journeys} journey={journey} handleJourney={handleJourney}
                                       events={events} handleEvent={handleEvent}/>
                        }


                        {/*{vehicle &&

                        events.features.length > 0 &&



                        <ListEventsVertical events={events} date={date} handleJourney={handleJourney}
                                            handleEvent={handleEvent}/>


                        }*/}



                    </div>





                    <Map2 vehicles={vehiclesFilter} journey={journey} event={event} events={events} zoom={zoom}
                          handleVehicle={handleVehicle} handleJourney={handleJourney} handleEvent={handleEvent} handleEndPic={handleEndPic}
                          endPic={endPic}  sideBarWidthPx={sideBarWidthPx}
                    />




                </div>




            </main>
        </div>


    );
}


export default MapParent;
