// import React, {useEffect, useState} from "react";

import React from "react";
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";

const VehicleDetail = ({vehicle, chips, addChip}) => {


    const vStyle = {
        background: '#ffffff',
        width: '100%',
        padding: '5px 5px 5px 2px',
        borderBottom: 'solid 1px #E4E4E4',
        borderLeft: 'solid 1px #E4E4E4'
    }

    const dStyle = {
        padding: '0.2rem 0.5rem',
        margin: '3px',
        background: '#dbd9d9',
        color: '#404040',
    }


    return(
        <div style={vStyle}>

        {/*<pre>{JSON.stringify(vehicle, null, 2)}</pre>*/}








                <span style={{paddingBottom: '5px', display: 'inline-block'}}>
                    <strong>{vehicle.properties.registration}</strong>    {vehicle.properties.driver}    {vehicle.properties.dn}
                    </span>

                {vehicle.properties.tags && vehicle.properties.tags.map(t => (



                    <Button style={dStyle} key={t} label={t} className="p-mb-2" onClick = {(e) => (addChip(t))}   />


                   // addChip(e.target.value)


                ))}




        </div>

    )
}

export default VehicleDetail;


// #F8F2F8
