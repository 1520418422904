
import {FaBeer, FaTrafficLight, FaSatellite} from 'react-icons/fa';
import {render} from "react-dom";


class MapCustomControl {



    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';

        this.zoomin = document.createElement('button');
        this.zoomin.className = 'mapboxgl-ctrl-zoom-in';
        this.zoomin.onclick = () => {  this.map.zoomIn({duration: 500});  };


        this.zoomout = document.createElement('button');
        this.zoomout.className = 'mapboxgl-ctrl-zoom-out';
        this.zoomout.onclick = () => {  this.map.zoomOut({duration: 500});  };
        this.zoomout.textContent = '-';

        this.traff = false;
        this.traffic = document.createElement('button');
        this.traffic.className = 'mapboxgl-ctrl-traffic';
        this.traffic.onclick = () => {

                if (this.traff) {
                    this.map.setPaintProperty('traffic-low', 'line-opacity', 0);
                    this.map.setPaintProperty('traffic-heavy', 'line-opacity', 0);
                    this.map.setPaintProperty('traffic-moderate', 'line-opacity', 0);
                    this.map.setPaintProperty('traffic-severe', 'line-opacity', 0);
                } else {
                    this.map.setPaintProperty('traffic-low', 'line-opacity', 1);
                    this.map.setPaintProperty('traffic-heavy', 'line-opacity', 1);
                    this.map.setPaintProperty('traffic-moderate', 'line-opacity', 1);
                }

                this.traff = !this.traff;


        };


        //var thumbsup = <FaTrafficLight />;

        //this.icon = document.createElement(thumbsup);
        // this.traffic.textContent = 'traffic';

        this.satellite = false;
        this.togsatellite = document.createElement('button');
        this.togsatellite.className = 'mapboxgl-ctrl-satellite';
        this.togsatellite.onclick = () => {

            if (this.satellite) {
                this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);


            } else {
                this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
                this.map.flyTo({
                    center: this.map.getCenter(),
                    zoom: 16,
                    speed: 2,
                });

            }

            this.satellite = !this.satellite;

        };
        // this.togsatellite.textContent = 'sat';


        this.container.appendChild(this.zoomin);
        this.container.appendChild(this.zoomout);
        this.container.appendChild(this.togsatellite);
        this.container.appendChild(this.traffic);

        console.log(this.container);







        return (
            this.container
        )






    }







    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }





}

export default MapCustomControl;










/*const MapCustomControl = () => {



    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        this.container.className = 'my-custom-control';
        this.container.textContent = 'My custom control poop';
        return this.container;
    };




    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }


}

export default MapCustomControl;*/
