/* src/components/Popup.js */
import React from 'react';

const Popup = ({ feature }) => {
    const { id, name, description } = feature.properties;

    function activateLasers() {
        console.log('do something' );
    }

    // const { lat, lng } = feature.properties;

    //console.log(feature);
    //console.log(feature.properties);




    return (
        <div id={`popup-${id}`}>
           {/*{feature.geometry.coordinates}*/}



            <svg id="circle" xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 130 30">
                <defs>
                    <style>{`
                        .small {
                        font-size: 15px;
                        font-weight: bold;
                        text-anchor: middle;
                        font-family: "Arial Black", serif
                    }
                        .mph {
                        font-size: 11px;
                        font-weight: normal;
                        text-anchor: middle;
                        font-family: "Arial", serif;
                        color: #333333;
                        fill:  #333333;
                    }`}
                    </style>
                </defs>

                <circle cx="15" cy="15" r="13" stroke="black" strokeWidth="3" fill="white"/>
                <text class="small" x="15" y="20"> {feature.properties.speed} </text>
                <text class="mph" x="43" y="20">mph</text>
                <g>
                <circle cx="75" cy="15" r="13" stroke="red" strokeWidth="3" fill="white"/>
                <text class="small" x="75" y="20"> 70 </text>
                <text class="mph" x="105" y="20">zone</text>
            </g>
        </svg>

            <br />
            <button onClick={activateLasers}>do something</button>

          <p>  {feature.properties.time}</p>

           {/* <pre>{JSON.stringify(feature.properties, null, 2)}</pre>*/}

            <p>
            gpsAcc: {feature.properties.gpsAcc},
            sigInt: {feature.properties.sigInt},
            netType: {feature.properties.netType},
            storeForward: {feature.properties.storeForward && <span>true</span>},
                alarm: {feature.properties.alarm},


        </p>


</div>



    );
}

export default Popup;



/*
geometry: {
    type: 'Point',
        coordinates: [longitude, latitude],
},*/
