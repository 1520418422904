import React from 'react';
import {Calendar} from 'primereact/calendar';


const DatePicker = ({date, handleDate}) => {


    return (



            <Calendar   showIcon dateFormat="dd/mm/yy" value={date} onChange={(e) => handleDate(e.value)}/>




    );
}

export default DatePicker;
