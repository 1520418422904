import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MapParent from './components/MapParent'

// import reportWebVitals from './reportWebVitals';

import 'primereact/resources/themes/md-light-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Dashboard from './dashboard/Dashboard.js';
import Preferences from './preferences/Preferences.js';
import Login from './login/Login.js';

import useToken from './login/useToken.js';
import ResizeObserver from "resize-observer-polyfill";


import {ReactComponent as MflLogo} from './svgs/logo-myfleetlive.svg';



const App = () => {


    const { token, setToken } = useToken();

    console.log(token);


/*    useEffect(() => {
        console.log('token changed');
        console.log(token);
    }, [token]);*/



    if(!token) {
        return <Login setToken={setToken}/>
    }







    return (



            <section>

                <header>


                    <MflLogo className="logo" width="180px" style={
                        {fill:'#000000'}
                    }/>

                    &nbsp;{token.result.email}  &nbsp; {token.result.name}  &nbsp;

                    <a href="/dashboard">dashboard</a> | <a href="/preferences">preferences</a>



                    <BrowserRouter>
                        <Routes>
                           {/* <Route path="/dashboard"   element={<Dashboard />} />
                            <Route path="/preferences"   element={<Preferences />} />*/}

                        </Routes>
                    </BrowserRouter>



                </header>

                <div  className="content" >
                    <MapParent   />
                </div>



                <footer>

                    <img className="logo" src="/svgs/logo-myfleetlive.svg" width="120px"/>


                </footer>


            </section>





)
}


ReactDOM.render(<App />, document.querySelector('#root'));






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
